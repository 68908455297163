import React from 'react';
import { Button } from '@wds/button';
import { Theme } from '@wds/styles';
import { createUseStyles } from 'react-jss';
import { ProgressCircle } from '@wds/progress-circle';

export const BUTTON_TEST_ID = 'UnlinkedButtonOptionButton';

const useStyles = createUseStyles((theme: Theme) => ({
  buttonOverride: {
    '& a, & button': {
      // reduce padding since we have some long titles and want to avoid breaking onto another
      // line if possible
      padding: [0, theme.spacing[3]],
    },
  },
}));

export default function UnlinkedButtonOption({
  isDisabled,
  showSpinner,
  titleText,
  onClick,
  id,
  href,
}: {
  isDisabled: boolean;
  showSpinner: boolean;
  titleText: string;
  id?: string;
  href?: string;
  onClick: () => void;
}): JSX.Element {
  let buttonTestId: string = BUTTON_TEST_ID;
  if (typeof id !== 'undefined') {
    const suffix = `__${id}`;
    buttonTestId = `${buttonTestId}${suffix}`;
  }

  const { buttonOverride } = useStyles();

  return (
    <div className={buttonOverride}>
      <Button
        stretched
        color="alt"
        size="md"
        data-testid={buttonTestId}
        disabled={isDisabled}
        onClick={onClick}
        href={href}
      >
        {/* Using ProgressCircle here to workaround https://momentiveai.atlassian.net/browse/WEBEXP-994,
            update when that ticket is fixed */}
        {showSpinner ? <ProgressCircle size="xs" continuous /> : titleText}
      </Button>
    </div>
  );
}

UnlinkedButtonOption.defaultProps = {
  id: undefined,
  href: undefined,
};
