import { defineMessages, t } from '@sm/intl';
import { createURL } from '@sm/utils';
import { Box } from '@wds/box';
import { Theme } from '@wds/styles';
import { Typography } from '@wds/typography';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import CTASplitter from '~app/components/CTASplitter/CTASplitter';
import { AppState } from '~app/handlers/handlers';
import navigateTo from '~app/helpers/navigateTo';
import { removeIdToken } from '~app/helpers/sessionStorage';
import SessionClient, { SessionResult } from '~app/helpers/sessionsApiClient';
import UnlinkedWindow from '~app/pages/login/UnlinkedPage/UnlinkedWindow';
import { QueryParams, SmParams } from '~common/entities/smParams';
import cleanObject from '~common/utils/cleanObject';
import getQueryParam, { hasQueryParam } from '../../../helpers/queryParams';
import UnlinkedButtonOption from './UnlinkedButtonOption';
import UnlinkedErrorBanner from './UnlinkedErrorBanner';
import UnlinkedHeader from './UnlinkedHeader';
import getAndValidateIdToken from './tokenHelper';
import { AuthState } from './types';

export const TEST_ID = 'UnlinkedPage';

const COPY = defineMessages({
  unlinkedPageSignupButtonTitle: {
    id: 'login.UnlinkedPageContent.SignupTitle',
    defaultMessage: 'Create a new account',
    description: '[Type: Button][Vis: high] - Button header for the user to create a new account',
  },
  unlinkedPageSignupButtonContent: {
    id: 'login.UnlinkedPageContent.SignupContent',
    defaultMessage: 'Sign up for SurveyMonkey using your {socialName} account – it’s free and easy',
    description:
      '[Type: Button][Vis: high] - Button text informing the user what happens if they click "Create a new account"',
  },
  unlinkedPageLoginButtonTitle: {
    id: 'login.UnlinkedPageContent.LoginTitle',
    defaultMessage: 'Log in to an existing account',
    description: '[Type: Button][Vis: high] - Button header for the user to log in to an existing account',
  },
  unlinkedPageLoginButtonEmailInUseTitle: {
    id: 'login.UnlinkedPageContent.LoginEmailInUseTitle',
    defaultMessage: 'Log in to your account',
    description:
      '[Type: Button][Vis: high] - Button header for the user to log in to the existing account linked to their social email',
  },
  unlinkedPageLoginButtonContent: {
    id: 'login.UnlinkedPageContent.LoginContent',
    defaultMessage: 'Once you log in to SurveyMonkey, you can link your {socialName} account from the My Account page',
    description:
      '[Type: Button][Vis: high] - Button text informing the user what happens if they click "Log in to an existing account"',
  },
  unlinkedPageEmailInUseMessage: {
    id: 'login.UnlinkedPageContent.EmailInUseMessage',
    defaultMessage: `<p>We found your account with {email}.</p><p>To log in, enter this email and select <b>Next</b> to enter your password.</p><p>You can’t use the Log in with {socialName} option until you’ve linked your SurveyMonkey and {socialName} accounts.</p>`,
    description: `[Type: Paragraph][Vis: med] - Body text informing the user of what steps they must take in order to login. "Next" is the button label defined in LoginEmailPage:submitButtonLabel`,
  },
  unlinkedPageLoginButton: {
    id: 'login.UnlinkedPageContent.LogIn',
    defaultMessage: 'Log in',
    description: `[Type: Button][Vis: high] - Label for the "Log in" button`,
  },
  unlinkedPageOptionsHeaderNoEmail: {
    id: 'login.UnlinkedPageContent.OptionsHeaderNoEmail',
    defaultMessage: `What would you like to do?`,
    description: '[Type: Paragraph][Vis: med] - Header asking user what they would like to do to link their account',
  },
});

const useStyles = createUseStyles((theme: Theme) => {
  return {
    emailInUseButtonContainer: {
      width: '240px',
    },
  };
});

export default function UnlinkedPageContent(): JSX.Element {
  const { idToken, socialName } = getAndValidateIdToken();
  const { emailInUseButtonContainer } = useStyles();

  const appState: AppState = {
    app: getQueryParam(QueryParams.SM_APP),
    ep: getQueryParam(QueryParams.EP),
    forceAccountPicker: hasQueryParam(SmParams.SM_FORCE_ACCOUNT_PICKER) ? true : undefined,
    sm: getQueryParam(QueryParams.SM),
    sm_allow_create_user: getQueryParam(QueryParams.SM_ALLOW_CREATE_USER),
    ut_source: getQueryParam(QueryParams.UT_SOURCE),
    ut_source2: getQueryParam(QueryParams.UT_SOURCE2),
    ut_source3: getQueryParam(QueryParams.UT_SOURCE3),
  };

  const emailInUse = !!getQueryParam(QueryParams.EMAIL_IN_USE);
  const userEmail = idToken.email as string;
  // For the "Create a new account" button
  const [signupState, setSignupState] = useState<AuthState>('pageload');
  const [signupJWTError, setSignupJWTError] = useState<boolean>(false);

  const attemptSignUp = async (): Promise<void> => {
    if (signupState === 'pending' || signupState === 'success') {
      return;
    }
    setSignupState('pending');

    try {
      const result = await SessionClient().createBySignup({ claims: idToken, appState });
      if (result === SessionResult.CREATED) {
        setSignupState('success');
        removeIdToken();
        navigateTo(
          '/login/next',
          cleanObject({
            ep: appState.ep,
            joined: 'true',
            sm: appState.sm,
            ut_source: appState.ut_source,
            ut_source2: appState.ut_source2,
            ut_source3: appState.ut_source3,
          })
        );
      } else {
        setSignupState('error');
        setSignupJWTError(result === SessionResult.INVALID_JWT);
      }
    } catch (error: unknown) {
      setSignupJWTError(false);
      setSignupState('error');
    }
  };

  // For the "Log in to an existing acct" button
  const [loginState, setLoginState] = useState<AuthState>('pageload');

  const attemptLogin = (): void => {
    // navigation to /login handled by href attr; just set state here
    if (loginState !== 'pending' && loginState !== 'success') {
      setLoginState('pending');
    }
  };

  const generateLoginURL = (): string => {
    return createURL(
      '/login',
      cleanObject({
        [SmParams.SM_APP]: appState.app,
        [SmParams.SM_FORCE_ACCOUNT_PICKER]: appState.forceAccountPicker ? '' : undefined,
        [SmParams.SM_EMAIL]: userEmail,
        ep: appState.ep,
        sm: appState.sm,
        sm_allow_create_user: appState.sm_allow_create_user,
        ut_source: appState.ut_source,
        ut_source2: appState.ut_source2,
      })
    );
  };

  const allowAccountCreation = (): boolean => {
    const baseUrl = window.location.href;
    return !baseUrl.includes('eu.');
  };

  // For logic affecting either/both buttons
  const areButtonsDisabled: boolean =
    loginState === 'pending' || loginState === 'success' || signupState === 'pending' || signupState === 'success';

  const loginButton = (label: string): JSX.Element => (
    <UnlinkedButtonOption
      isDisabled={areButtonsDisabled}
      showSpinner={loginState === 'pending' || loginState === 'success'}
      titleText={label}
      onClick={attemptLogin}
      id="LOGIN"
      href={generateLoginURL()}
    />
  );

  const EmailInUse = (): JSX.Element => {
    return (
      <Box mx={4}>
        <Typography>
          {t(
            COPY.unlinkedPageEmailInUseMessage,
            {
              email: userEmail,
              socialName,
            },
            { html: true }
          )}
        </Typography>
        <Box mt={5} mb={2} display="flex" flexJustify="center">
          <Box className={emailInUseButtonContainer}>{loginButton(t(COPY.unlinkedPageLoginButton))}</Box>
        </Box>
      </Box>
    );
  };

  const CreateOrLogin = (): JSX.Element => {
    const signupCTA = {
      id: 'cta.signup',
      cta: (
        <UnlinkedButtonOption
          isDisabled={areButtonsDisabled}
          showSpinner={signupState === 'pending' || signupState === 'success'}
          titleText={t(COPY.unlinkedPageSignupButtonTitle)}
          onClick={() => {
            void attemptSignUp();
          }}
          id="SIGNUP"
        />
      ),
      caption: <Typography>{t(COPY.unlinkedPageSignupButtonContent, { socialName })}</Typography>,
    };

    const loginCTA = {
      id: 'cta.login',
      cta: loginButton(t(COPY.unlinkedPageLoginButtonTitle)),
      caption: <Typography>{t(COPY.unlinkedPageLoginButtonContent, { socialName })}</Typography>,
    };

    return (
      <CTASplitter
        prompt={t(COPY.unlinkedPageOptionsHeaderNoEmail)}
        ctas={allowAccountCreation() ? [signupCTA, loginCTA] : [loginCTA]}
      />
    );
  };

  return (
    <UnlinkedWindow
      addOnTop={
        /* HEADER */
        <UnlinkedHeader socialName={socialName} emailInUse={emailInUse} />
      }
    >
      <main data-testid={TEST_ID}>
        {/* OPTIONAL ERROR BANNER */}
        {signupState === 'error' ? <UnlinkedErrorBanner JWTError={signupJWTError} /> : null}

        {emailInUse ? <EmailInUse /> : <CreateOrLogin />}
      </main>
    </UnlinkedWindow>
  );
}
