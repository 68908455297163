import { Auth0Client, IdToken } from '@auth0/auth0-spa-js';
import { clientErrorHandler } from '@sm/webassets';
import { AppState } from '../handlers';

/**
 * Handles the OAuth callback, extracting the ID Token claims, and AppState that was
 * saved with the transaction.
 *
 * @param client Configured Auth0 client
 * @returns ID Token claims and app state.
 */
export default async function handleRedirectCallback(
  client: Auth0Client
): Promise<{ appState: AppState; claims: IdToken }> {
  try {
    const result = await client.handleRedirectCallback();
    const claims = await client.getIdTokenClaims();
    const appState: AppState = result.appState || {};

    if (!claims) {
      throw new Error('Missing ID token');
    }

    return { appState, claims };
  } catch (e: unknown) {
    if (e instanceof Error) {
      clientErrorHandler.logError(e, 'Failed to get ID token');
    }
    throw e;
  }
}
