/** The names of known "sm.<whatever>" query params that can be added to the auth URL */
export enum SmParams {
  SM_APP = 'sm.app',
  SM_COUNTRY = 'sm.country',
  SM_EMAIL = 'sm.email',
  SM_FORCE_ACCOUNT_PICKER = 'sm.force_account_picker',
  SM_INVITE_TOKEN = 'sm.invite_token',
  SM_LOCALE = 'sm.locale',
  SM_SUBDOMAIN = 'sm.subdomain',
  SM_ORIGIN = 'sm.origin',
  SM_SOCIAL_POPUP = 'sm.social_popup',
  SM_SHOW = 'sm.show',
  // amplitude device ID
  SM_AMP_DID = 'sm.amp_did',
  // amplitude session ID
  SM_AMP_SID = 'sm.amp_sid',
}

/** Possible values for the sm.origin param */
export enum SmOrigin {
  SURVEYENDPAGE = 'surveyendpage',
}

/** Inbound query params that we understand */
export enum QueryParams {
  CONNECTION = 'connection',
  EMAIL_IN_USE = 'email_in_use',
  ERROR = 'error',
  ERROR_DESCRIPTION = 'error_description',
  EP = 'ep',
  SM = 'sm',
  SM_ALLOW_CREATE_USER = 'sm_allow_create_user',
  SM_APP = 'sm.app',
  SM_LOCALE = 'sm.locale',
  SM_SHOW = 'sm.show',
  UT_SOURCE = 'ut_source',
  UT_SOURCE2 = 'ut_source2',
  UT_SOURCE3 = 'ut_source3',
}

export default SmParams;
