import 'regenerator-runtime/runtime';
import 'core-js/stable';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import createBrowserApolloClient from '@sm/apollo-clients/dist/browser';
import { startRumTracing, traceClientOperation, traceApolloError } from '@sm/otel-web';
import { clientErrorHandler } from '@sm/webassets';
import possibleTypes from './helpers/possibleTypes';
import { bootstrapSMWeb, createApp, getLocaleMessages, mount } from './utils';

const { staticData, environ, locale } = bootstrapSMWeb(window);
const {
  openTelemetry,
  pageRequestId,
  'client-config': { appName, appVersion, graphQLUri },
  gql: { gqlCache = {} } = {},
} = staticData;

const apolloClient: ApolloClient<NormalizedCacheObject> = createBrowserApolloClient({
  uri: graphQLUri,
  cacheHydration: gqlCache,
  pageRequestId,
  possibleTypes,
  customLinks: [traceClientOperation({ clientName: appName, appVersion }), traceApolloError],
  linkOptions: {
    credentials: 'include',
    batchInterval: 30,
  },
  metadata: { appName, appVersion },
  availableLoggedOutPaths: ['/lo-graphql/teamJoin'],
});

// if there is no opener, open the page right away
let timeout = 1;
if (window.opener) {
  // make the timeout longer to wait for the opener to tell this page to close
  timeout = 200;

  // wait for a message from the opener to close this window
  window.addEventListener('message', event => {
    if (event.data === 'done') {
      window.close();
    }
  });

  // tell the opener where to navigate to
  window.opener.postMessage(
    JSON.stringify({
      location: window.location.href,
    }),
    '*'
  );
}

if (openTelemetry) {
  const {
    app,
    beaconUrl,
    environment: deploymentEnv,
    rumAuth,
    version,
    enabled,
    sampleRate,
    ...options
  } = openTelemetry;

  startRumTracing({
    enabled,
    beaconUrl,
    rumAuth,
    appName: app,
    version,
    env: deploymentEnv,
    sampleRate: Number(sampleRate),
    ...options,
  });
}

setTimeout(() => {
  void getLocaleMessages(locale)
    .then(createApp(environ, staticData, apolloClient))
    .then(mount(document))
    .catch(clientErrorHandler.logError);
}, timeout);
