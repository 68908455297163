/* Helper functions for parsing Amplitude session. These are consumed by BOTH client and server code */

export type AmplitudeSessionInfo = {
  deviceId?: string;
  sessionId?: number;
};

function atob(input: string): string {
  // This works in the browser or Node.js
  return typeof window !== 'undefined' ? window.atob(input) : Buffer.from(input, 'base64').toString('latin1');
}

export function getAmplitudeStorageKey(amplitudeToken: string): string {
  // the amplitude SDK names its storage key `AMP_<first ten characters of token>`
  return `AMP_${amplitudeToken.substring(0, 10)}`;
}

/**
 * Parse Amplitude session from a string
 * @param json A string containing JSON data
 */
export function parseAmplitudeSession(json: string | undefined | null): AmplitudeSessionInfo {
  if (typeof json !== 'string') {
    return {};
  }

  try {
    const parsed = JSON.parse(json);
    return {
      deviceId: parsed.deviceId,
      sessionId: parsed.sessionId,
    };
  } catch (e: unknown) {
    // give up
    return {};
  }
}

/**
 * Parse Amplitude session from cookie
 *
 * @param cookies Object containing cookies as name-value pairs
 */
export default function parseAmplitudeCookie(
  cookies: Record<string, string>,
  amplitudeToken: string
): AmplitudeSessionInfo {
  const storageKey = getAmplitudeStorageKey(amplitudeToken);

  let cookieData = cookies[storageKey];
  if (!cookieData) {
    return {};
  }

  // Normalize it before parsing
  cookieData = decodeURIComponent(atob(cookieData));

  return parseAmplitudeSession(cookieData);
}
