type Input = Record<string, string | undefined>;

/**
 * Helper function that asserts a value that:
 *  - we know is a string (or expect is a string)
 *  - its typing says it _could_ be of type not-a-string
 * gets returned as type string.
 *
 * @param v any value that should be a string
 * @returns the value asserted as a string, or undefined if the value isn't a string
 */
export function asString(v: unknown): string | undefined {
  return typeof v === 'string' ? v : undefined;
}

/** @returns a copy of `target` with all `undefined`-valued properties omitted */
export default function cleanObject(target: Input): Record<string, string> {
  const copy: Record<string, string> = {};

  Object.entries(target).forEach(([key, value]) => {
    if (value !== undefined) {
      copy[key] = value;
    }
  });

  return copy;
}
