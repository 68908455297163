export type Locale =
  | 'da'
  | 'de'
  | 'en'
  | 'en-xa' // Pseudo-locale
  | 'es'
  | 'fi'
  | 'fr'
  | 'it'
  | 'ja'
  | 'ko'
  | 'nl'
  | 'no'
  | 'pt-br'
  | 'ru'
  | 'sv'
  | 'tr'
  | 'zh-tw';

const defaultLocale = 'en';

export const getLocale = (locale: String | undefined): Locale => {
  if (locale) {
    switch (locale) {
      case 'da':
      case 'de':
      case 'en':
      case 'en-xa':
      case 'es':
      case 'fi':
      case 'fr':
      case 'ko':
      case 'nl':
      case 'no':
      case 'ja':
      case 'it':
      case 'ru':
      case 'pt-br':
      case 'sv':
      case 'tr':
      case 'zh-tw':
        return locale as Locale;
      default:
        return defaultLocale as Locale;
    }
  }
  return defaultLocale as Locale;
};

/**
 * These are the language codes supported by the SurveyMonkey help center
 */
export type HelpCenterLocale = 'es-la' | 'de' | 'fr' | 'nl' | 'pt-br' | 'ja' | 'it' | 'tr' | 'ru' | 'ko' | 'zh' | 'en';

/**
 * Maps an app locale to a help center locale
 *
 * @returns the help center locale
 */
export const getHelpCenterLocale = (locale: Locale | undefined): HelpCenterLocale => {
  switch (locale) {
    case 'de':
    case 'fr':
    case 'nl':
    case 'ja':
    case 'it':
    case 'tr':
    case 'ru':
    case 'ko':
    case 'pt-br':
      return locale;

    case 'es':
      return 'es-la';

    case 'zh-tw':
      return 'zh';

    case 'da':
    case 'en':
    case 'en-xa':
    case 'fi':
    case 'no':
    case 'sv':
    default:
      return 'en';
  }
};

/**
 * These are the language codes supported by Auth0
 */
export type Auth0SupportedLocale =
  | 'da'
  | 'de'
  | 'en'
  | 'en-xa'
  | 'es'
  | 'fi'
  | 'fr'
  | 'it'
  | 'ja'
  | 'ko'
  | 'nl'
  | 'no'
  | 'pt-BR'
  | 'ru'
  | 'sv'
  | 'tr'
  | 'zh-TW';

/**
 * Maps an app locale to an Auth0 supported locale
 *
 * @returns the auth0 supported locale
 */
export const getAuth0SupportedLocale = (locale: Locale): Auth0SupportedLocale => {
  switch (locale) {
    case 'pt-br':
      return 'pt-BR';
    case 'zh-tw':
      return 'zh-TW';
    default:
      return locale;
  }
};
