import { IdToken } from '@auth0/auth0-spa-js';
import { clientErrorHandler } from '@sm/webassets';
import { UtSourceParams } from 'src/common/entities/user';
import { Auth0Strategy, getStrategyBySub } from '~common/entities/auth0Strategy';
import { FormattedError, SamlErrorCode } from '~common/errors/userFacing';
import { AppState, SmApp } from '../handlers/handlers';
import { getAmplitudeIds, waitForAmplitude } from '~app/utils/amplitudeHelpers';
import { AmplitudeSessionInfo } from '~common/helpers/amplitudeSession';

// The types of errors that the server can return from POST /api/v1/sessions
export class IdentityAlreadyLinkedError extends Error {}

export class SamlError extends Error {
  code: SamlErrorCode;

  constructor(detail: string, code: SamlErrorCode) {
    super(detail);
    this.code = code;
  }
}

export class IdentityNotLinkedError extends Error {
  /**
   * Whether the email asserted by the social identity is in use by an existing account
   */
  emailInUse: boolean;

  constructor(emailInUse = false) {
    super();
    this.emailInUse = emailInUse;
  }
}
export class InvalidJWTError extends Error {}
export class InvalidInviteError extends Error {}

export enum SessionResult {
  /** Could not create a session because no account was linked. User intervention is needed. */
  UNLINKED = 1,

  /** Could not create a session because of issue detected with the JWT (with no further detail, intentionally) */
  INVALID_JWT = 2,

  /** Existing user successfully logged in */
  LOGGED_IN = 3,

  /** New user created and then logged in */
  CREATED = 4,

  /** User login via SSO */
  SSO_LOGIN = 5,
}

/** Which type of account we'd prefer to log into */
export type AccountTypePreference = 'contribute' | 'rewards' | undefined;

/** Return value from sucessful POST /sessions call */
type SessionResponseBody = {
  user: {
    id: string;
  };
  redirectUrl?: string;
};

function toPref(smApp?: SmApp): AccountTypePreference {
  switch (smApp) {
    case undefined:
    case SmApp.CORE:
    case SmApp.MOBILE:
      return undefined;
    case SmApp.CONTRIBUTE:
      return 'contribute';
    case SmApp.REWARDS:
      return 'rewards';
  }
}

export class SessionClient {
  readonly endpointUrl = '/login/api/v1/sessions';

  private static getSignupParams(appState: AppState): Pick<AppState, 'ut_source' | 'ut_source2' | 'ut_source3'> {
    const { ut_source, ut_source2, ut_source3 } = appState;
    return { ut_source, ut_source2, ut_source3 };
  }

  /**
   * @returns Whether we should create a SurveyMonkey account linked to the user's token subject
   * without prompting the user
   */
  private static shouldCreateAccount(
    appState: AppState,
    claims: IdToken,
    notLinkedError: IdentityNotLinkedError
  ): boolean {
    // Create an account when the user authenticated via Auth0 strategy (i.e. email+password).
    // This is the typical case when a user has just created an Auth0 email identity.
    if (getStrategyBySub(claims.sub) === Auth0Strategy.Auth0) {
      return true;
    }

    // Create an account if the 'force' param is set, and the email isn't already in use
    if (appState.forceAccountCreation && !notLinkedError.emailInUse) {
      return true;
    }

    return false;
  }

  private static handleError(body: { errors: FormattedError[] }): never {
    const {
      errors: [serverError],
    } = body;

    if (Object.values<string>(SamlErrorCode).includes(serverError.code)) {
      throw new SamlError(serverError.detail, serverError.code as unknown as SamlErrorCode);
    }

    switch (serverError.code) {
      case 'identity_already_linked':
        throw new IdentityAlreadyLinkedError();
      case 'identity_not_linked':
        throw new IdentityNotLinkedError(serverError.extra === 'email_in_use_by_password_account');
      case 'invalid_invite':
        throw new InvalidInviteError();
      case 'unauthorized':
        // The remote UserService rejected the JWT
        throw new InvalidJWTError();
      case 'invalid_request':
        if (
          serverError.source &&
          serverError.source.location === 'body' &&
          serverError.source.pointer === '.id_token'
        ) {
          // Shallow validation failed (probably because the JWT expired)
          throw new InvalidJWTError();
        }
        break;
      default:
        break; // will throw generic error below
    }

    const error = new Error('Failed to create session');
    clientErrorHandler.logError(error);
    throw error;
  }

  /**
   * Authenticate using the identity token, by first attempting to login and creating a new SM user
   * if necessary.
   *
   * @throws `IdentityAlreadyLinkedError` if the identity is already linked to an SM user.
   * @throws Generic error on other failure.
   * @returns A SessionResult and optionally a detailed error field
   */
  async createByLogin({
    claims,
    appState,
    smAllowCreateUser,
  }: {
    claims: IdToken;
    appState: AppState;
    smAllowCreateUser?: string;
  }): Promise<{ result: SessionResult; error?: IdentityNotLinkedError; redirectUrl?: string }> {
    const idToken = claims.__raw;

    // Try to login
    let notLinkedError: IdentityNotLinkedError | undefined;
    try {
      const response = await this.postSessions({
        idToken,
        action: 'login',
        preference: toPref(appState.app),
        authUrl: appState.authUrl,
      });

      if (response.redirectUrl) {
        return { result: SessionResult.SSO_LOGIN, redirectUrl: response.redirectUrl };
      }

      return { result: SessionResult.LOGGED_IN };
    } catch (e: unknown) {
      if (!(e instanceof IdentityNotLinkedError)) {
        throw e;
      }
      notLinkedError = e;
    }

    // Identity is not linked to an SM account. Check if we should create one.
    if (SessionClient.shouldCreateAccount(appState, claims, notLinkedError)) {
      const result = await this.createBySignup({ claims, appState, smAllowCreateUser });
      return { result };
    }

    // Identity not linked and we couldn't create an account for them. Just return unlinked, and
    // let the user decide how to proceed.
    return { result: SessionResult.UNLINKED, error: notLinkedError };
  }

  /**
   * Authenticate using the identity token, by creating a new SM user on the fly.
   *
   * @param claims The ID token
   * @param appState
   * @param inviteToken Optional invite token if user is being created in the context of
   * a team invite workflow
   *
   * @throws `IdentityAlreadyLinkedError` if the identity is already linked to an SM user.
   * @throws Generic error on other failure.
   */
  async createBySignup({
    claims,
    appState,
    inviteToken = undefined,
    smAllowCreateUser = undefined,
  }: {
    claims: IdToken;
    appState: AppState;
    inviteToken?: string;
    smAllowCreateUser?: string;
  }): Promise<SessionResult> {
    const idToken = claims.__raw;

    try {
      await this.postSessions({
        idToken,
        action: 'signup',
        signupParams: SessionClient.getSignupParams(appState),
        inviteToken,
        smAllowCreateUser,
        authUrl: appState.authUrl,
        preference: toPref(appState.app),
      });
      return SessionResult.CREATED;
    } catch (e: unknown) {
      if (e instanceof InvalidJWTError) {
        return SessionResult.INVALID_JWT;
      }
      throw e;
    }
  }

  /**
   * Authenticate an existing SM user using the identity token.
   *
   * @param idToken
   * @throws IdentityNotLinkedError
   * @throws InvalidJWTError
   */
  async login(idToken: IdToken): Promise<SessionResponseBody> {
    return this.postSessions({ action: 'login', idToken: idToken.__raw });
  }

  /**
   * Switch to a linked account by the active session.
   *
   * @param targetUserId Id of the linked user account being switched to
   */
  async switch(targetUserId: string): Promise<SessionResponseBody> {
    return this.postSessions({ action: 'switch', targetUserId });
  }

  private async postSessions(
    opts:
      | {
          action: 'login';
          idToken: string;
          preference?: AccountTypePreference;
          authUrl?: string;
        }
      | {
          action: 'signup';
          idToken: string;
          signupParams: UtSourceParams;
          inviteToken?: string;
          smAllowCreateUser?: string;
          authUrl?: string;
          preference?: AccountTypePreference;
        }
      | { action: 'switch'; targetUserId: string }
  ): Promise<SessionResponseBody> {
    let ampIds: AmplitudeSessionInfo = {};
    if (opts.action === 'login' || opts.action === 'signup') {
      await waitForAmplitude();
      ampIds = getAmplitudeIds();
    }

    const resp = await fetch(this.endpointUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        action: opts.action,
        id_token: 'idToken' in opts ? opts.idToken : undefined,
        preference: opts.action === 'login' || opts.action === 'signup' ? opts.preference : undefined,
        signup_params: opts.action === 'signup' ? opts.signupParams : undefined,
        invite_token: opts.action === 'signup' ? opts.inviteToken : undefined,
        sm_allow_create_user: opts.action === 'signup' ? opts.smAllowCreateUser : undefined,
        target_user_id: opts.action === 'switch' ? opts.targetUserId : undefined,
        auth_url: opts.action === 'login' || opts.action === 'signup' ? opts.authUrl : undefined,
        sm_amp_sid: ampIds.sessionId ?? undefined,
        sm_amp_did: ampIds.deviceId ?? undefined,
      }),
    });

    if (resp.status === 200) {
      return (await resp.json()) as SessionResponseBody;
    }

    return SessionClient.handleError(await resp.json());
  }
}

export default (): SessionClient => new SessionClient();
