type Messages = { [K: string]: string };

const DEFAULT_LOCALE = 'en-US';
const FALLBACK = {};

export default async function getLocaleMessages(locale: string): Promise<Messages> {
  if (locale === DEFAULT_LOCALE) {
    // Default locale will use the default fallback strings
    return FALLBACK;
  }
  try {
    const [appMessages, webassetsMessages, headerMessages, seatModalRequestMessages, infiniteScrollTriggerMessages] =
      await Promise.all([
        import(/* webpackChunkName: "i18n/[request]" */ `../../locales/translated/${locale}`),
        import(/* webpackChunkName: "i18n/webassets/[request]" */ `@sm/webassets/dist/locales/translated/${locale}`),
        import(/* webpackChunkName: "i18n/header/[request]" */ `@sm/header/translated/${locale}`),
        import(
          /* webpackChunkName: "i18n/seat-request-modal/[request]" */ `@sm/seat-request-modal/translated/${locale}`
        ),
        import(
          /* webpackChunkName: "i18n/infinite-scroll-trigger/[request]" */ `@sm/infinite-scroll-trigger/translated/${locale}`
        ),
      ]);
    return {
      ...appMessages,
      ...webassetsMessages,
      ...headerMessages,
      ...seatModalRequestMessages,
      ...infiniteScrollTriggerMessages,
    };
  } catch (err: unknown) {
    return FALLBACK;
  }
}
