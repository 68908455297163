import { MetricsTracker } from '@sm/metrics';
import { StaticContext } from '@sm/webassets';
import React, { PropsWithChildren, useContext, useEffect, useRef } from 'react';

const useInitialRender = (): boolean => {
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    }
  });

  return isInitialRender.current;
};

export default function BaseLoadingPage({ children, pageId }: PropsWithChildren<{ pageId: string }>): JSX.Element {
  const isInitialRender = useInitialRender();
  const { user, environment, 'client-config': clientConfig } = useContext(StaticContext);
  const { countryCode: country } = environment;
  const { gtmId, loggingAPIPath, dataAnalyticsAPIPath, amplitudeToken } = clientConfig;

  if (isInitialRender) {
    MetricsTracker.initialize({
      user,
      dataAnalyticsAPIPath,
      loggingAPIPath,
      country,
      legacyWeb: '',
      pageId,
      gtmId,
      amplitudeToken,
    });
  }

  return <>{children}</>;
}
