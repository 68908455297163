import { SamlErrorCode } from '~common/errors/userFacing';

import { ParseResult } from '..';
import { AbstractHandler } from '.';

export default class ConnectionDisabledHandler extends AbstractHandler {
  public handle(error: string, errorDescription: string): ParseResult | null {
    const isError = error === 'invalid_request' && errorDescription.includes('the connection was disabled');

    if (isError) {
      return {
        code: SamlErrorCode.CONNECTION_DISABLED,
      };
    }
    return super.handle(error, errorDescription);
  }
}
