import React from 'react';
import { defineMessages, T } from '@sm/intl';
import { Banner } from '@wds/banner';

export const TEST_ID = 'UnlinkedErrorBanner';
export const ERROR_TEST_ID = 'UnlinkedErrorBanner__Error';
export const JWT_ERROR_TEST_ID = 'UnlinkedErrorBanner__JWTError';

const COPY = defineMessages({
  unlinkedPageErrorBanner: {
    id: 'login.UnlinkedErrorBanner.BannerCopy',
    defaultMessage: 'Sorry, something went wrong. Please try again.',
    description: '[Type: Label][Vis: high] - Banner that shows when a signup attempt failed',
  },
  unlinkedPageJwtErrorBanner: {
    id: 'login.UnlinkedErrorBanner.JWTErrorBannerCopy',
    defaultMessage: "Your request expired. Please <a href='/login'>go back and try again</a>.",
    description:
      '[Type: Label][Vis: high] - Banner that instructs the user to take specific action when a signup attempt failed',
  },
});

export default function UnlinkedErrorBanner({ JWTError }: { JWTError: boolean }): JSX.Element {
  return (
    <Banner color="warning" showIcon data-testid={TEST_ID}>
      {JWTError ? (
        <span data-testid={JWT_ERROR_TEST_ID}>
          <T desc={COPY.unlinkedPageJwtErrorBanner} html />
        </span>
      ) : (
        <span data-testid={ERROR_TEST_ID}>
          <T desc={COPY.unlinkedPageErrorBanner} />
        </span>
      )}
    </Banner>
  );
}
