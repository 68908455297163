import { MetricsTracker } from '@sm/metrics';
import { parse as parseCookie } from 'cookie';
import parseAmplitudeCookie, {
  AmplitudeSessionInfo,
  getAmplitudeStorageKey,
  parseAmplitudeSession,
} from '~common/helpers/amplitudeSession';

/* Client-side Amplitude helper functions */

// How long we'll wait for Amplitude SDK to initialize before giving up
const amplitudeInitTimeoutMs = 150;

// Time between checks
const pollIntervalMs = 50;

/** Parse Amplitude session from local storage */
function parseAmplitudeStorage(s: Storage, amplitudeToken: string): AmplitudeSessionInfo {
  const storageKey = getAmplitudeStorageKey(amplitudeToken);
  const data = s.getItem(storageKey);
  return parseAmplitudeSession(data);
}

/**
 * Extracts the device and session IDs from the Amplitude cookie
 *
 * @returns An object containing the extracted device/session IDs
 */
export function getAmplitudeIds(): AmplitudeSessionInfo {
  const amplitudeToken = MetricsTracker?.getConfig()?.amplitudeToken;

  if (!amplitudeToken) {
    return {};
  }

  // Check for cookie storage
  const cookieTokens = parseCookie(document.cookie);
  const fromCookie = parseAmplitudeCookie(cookieTokens, amplitudeToken);

  if (fromCookie.deviceId && fromCookie.sessionId) {
    // Use the cookie values
    return fromCookie;
  }

  // Cookie not found, try localStorage fallback
  return parseAmplitudeStorage(window.localStorage, amplitudeToken);
}

/**
 * Polls a predicate function until it returns true or timeout elapses.
 *
 * @resolves To `'ok'` if condition returned true, or `'timeout'` if timeout elapsed
 * @rejects On runtime error in `condition()`
 */
async function waitFor(predicate: () => boolean): Promise<'ok' | 'timeout'> {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => resolve('timeout'), amplitudeInitTimeoutMs);

    const check = (): void => {
      try {
        if (predicate()) {
          clearTimeout(timeoutId);
          resolve('ok');
        } else {
          setTimeout(check, pollIntervalMs);
        }
      } catch (e: unknown) {
        const err = e as Error;
        clearTimeout(timeoutId);
        reject(err);
      }
    };

    setTimeout(check, 0); // start on next tick
  });
}

/**
 * This function forces the Amplitude SDK to start up, and returns once it has written the
 * session ID and device ID to storage. If we can't find the SID/DID in a reasonable time,
 * give up and return so we don't block the user's transaction.
 *
 * ⚠️ NOTE: this won't work in all cases: in particular, users who haven't yet consented to functional
 * cookies will not get an Amp session. But given the current @sm/metrics design this is the best
 * we can do.
 */
export async function waitForAmplitude(): Promise<void> {
  // Poll until we find a session or our timeout elapses
  await waitFor(() => !!getAmplitudeIds().sessionId);
}
