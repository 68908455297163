import { SamlErrorCode } from '~common/errors/userFacing';

import { ParseResult } from '..';
import { AbstractHandler } from '.';

export default class InvalidCertHandler extends AbstractHandler {
  public handle(error: string, errorDescription: string): ParseResult | null {
    const isError =
      error === 'access_denied' &&
      (errorDescription.startsWith('The signing certificate is invalid') ||
        errorDescription.startsWith('Signature check errors') ||
        errorDescription.startsWith('Signature is missing') ||
        errorDescription.startsWith('Signature was found more than one time'));

    if (isError) {
      return {
        code: SamlErrorCode.X509_CANNOT_VERIFY,
      };
    }
    return super.handle(error, errorDescription);
  }
}
