import { Auth0Client, IdToken } from '@auth0/auth0-spa-js';
import { MetricsTracker, USER_EVENTS } from '@sm/metrics';
import SessionClient, { IdentityNotLinkedError, SessionResult } from '../helpers/sessionsApiClient';
import handleRedirectCallback from './callback/handleRedirectCallback';
import navigateTo from '~app/helpers/navigateTo';
import { fetchAndRemoveEp, fetchAndRemoveSm, fetchAndRemoveSmAllow, stashIdToken } from '../helpers/sessionStorage';
import { Auth0Config } from '~app/helpers/auth0Config';
import cleanObject from '~common/utils/cleanObject';
import { SmParams } from '~common/entities/smParams';
import { AppState } from './handlers';
import RespondentSSOClient from '../helpers/respondentSSOApiClient';

const MFA_CUSTOM_CLAIMS_KEY = 'https://identity.surveymonkey.com/claims/mfa_status';

function handleSsoSessionResult(result: SessionResult, redirectUrl?: string): void {
  if (redirectUrl) {
    navigateTo(redirectUrl);
  } else {
    throw new Error(`redirectUrl not provided for SSO_LOGIN result: ${result}`);
  }
}

function handleLoggedInOrCreatedSessionResult(
  result: SessionResult,
  appState: AppState,
  ep?: string,
  sm?: string,
  bypassDeviceVerification = false
): void {
  navigateTo(
    '/login/next',
    cleanObject({
      ep,
      joined: result === SessionResult.CREATED ? 'true' : undefined,
      force_account_picker: appState.forceAccountPicker ? 'true' : undefined,
      sm,
      bypassDV: bypassDeviceVerification ? 'true' : undefined,
    })
  );
}

function handleUnlinkedSessionResult(
  appState: AppState,
  claims: IdToken,
  error?: IdentityNotLinkedError,
  ep?: string,
  sm?: string,
  smAllowCreateUser?: string
): void {
  const { app, forceAccountPicker, ut_source: utSource, ut_source2: utSource2 } = appState;
  const emailInUse = error?.emailInUse ? 'true' : undefined;
  stashIdToken(claims);
  navigateTo(
    '/login/unlinked',
    cleanObject({
      [SmParams.SM_APP]: app,
      [SmParams.SM_FORCE_ACCOUNT_PICKER]: forceAccountPicker ? '' : undefined,
      ep,
      sm,
      sm_allow_create_user: smAllowCreateUser,
      ut_source: utSource,
      ut_source2: utSource2,
      email_in_use: emailInUse,
    })
  );
}

/**
 * Tracks the MFA success event.
 */
function trackMFASuccessEvent(): void {
  MetricsTracker.track({
    name: USER_EVENTS.NAVIGATION,
    data: {
      amplitudeEvent: 'login mfa success',
    },
  });
}

/**
 * Completes a login transaction and establishes a session, then navigates the user
 *
 * @param config Auth0 configuration
 */
async function auth0Callback(
  config: Auth0Config,
  ep?: string,
  sm?: string,
  sm_allow_create_user?: string
): Promise<void> {
  const { domain, clientId } = config;
  if (!domain || !clientId) {
    throw new Error('Missing auth0 configuration');
  }

  const client = new Auth0Client({
    clientId,
    domain,
    nowProvider: () => config.now,
  });
  const { appState, claims } = await handleRedirectCallback(client);
  const { result, error, redirectUrl } = await SessionClient().createByLogin({
    claims,
    appState,
    smAllowCreateUser: sm_allow_create_user,
  });
  const isMfaEnabled = MFA_CUSTOM_CLAIMS_KEY in claims && claims[MFA_CUSTOM_CLAIMS_KEY] === 'ENABLED';

  if (isMfaEnabled) {
    // send event when MFA enabled user has successfully authenticated
    trackMFASuccessEvent();
  }

  switch (result) {
    case SessionResult.SSO_LOGIN:
      handleSsoSessionResult(result, redirectUrl);
      break;
    case SessionResult.LOGGED_IN:
    case SessionResult.CREATED:
      // bypassDeviceVerification when MFA is enabled
      handleLoggedInOrCreatedSessionResult(result, appState, ep, sm, isMfaEnabled);
      break;
    case SessionResult.UNLINKED:
      handleUnlinkedSessionResult(appState, claims, error, ep, sm, sm_allow_create_user);
      break;
    default:
      throw new Error(`Unhandled result: ${result}`);
  }
}

export default async function callback(config: Auth0Config, isLegacySso: boolean): Promise<void> {
  const ep = fetchAndRemoveEp() ?? undefined;
  const sm = fetchAndRemoveSm() ?? undefined;

  if (ep?.includes('sso_user')) {
    const { domain, clientId } = config;
    if (!domain || !clientId) {
      throw new Error('Missing auth0 configuration');
    }
    const client = new Auth0Client({
      clientId,
      domain,
      nowProvider: () => config.now,
    });
    const { claims } = await handleRedirectCallback(client);
    const respondentSSOData = await RespondentSSOClient().createRespondentSSOToken(claims, ep);
    navigateTo(respondentSSOData.redirectUrl ?? ep);
  } else if (isLegacySso) {
    navigateTo('/user/redirect', cleanObject({ ep, sm }));
  } else {
    const sm_allow_create_user = fetchAndRemoveSmAllow() ?? undefined;
    await auth0Callback(config, ep, sm, sm_allow_create_user);
  }
}
