/**
 * @returns a string value if it belongs to the enum.
 * @throws if the value is not a member of the enum.
 */
export default function asEnum<T extends string, TEnumValue extends string>(
  enumObject: { [K in T]: TEnumValue },
  value: unknown
): TEnumValue {
  const isOk = (v: unknown): v is TEnumValue => Object.values(enumObject).includes(value);

  if (isOk(value)) {
    return value;
  }

  throw new Error(`Value "${value}" is not a member of enum [${Object.values(enumObject).join(',')}]`);
}
