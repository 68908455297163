/* Error types that may be observed by the client */

export enum SamlErrorCode {
  // Usersvc codes
  DOMAIN_LOCKED = 'domain_locked',
  EMAIL_ROLE_BASED = 'email_role_based',
  GROUP_DOWNGRADED = 'group_downgraded',
  GROUP_HAS_NO_OWNER = 'group_has_no_owner',
  GROUP_NOT_FOUND = 'group_not_found',
  GROUP_NOT_FOUND_BY_CANONICAL_NAME = 'group_not_found_by_canonical_name',
  GROUP_NOT_FOUND_BY_ISSUER = 'group_not_found_by_issuer',
  GROUP_NOT_SSO_ENABLED = 'group_not_sso_enabled',
  GROUP_OWNER_IS_NOT_ENTERPRISE = 'group_owner_is_not_enterprise',
  INVALID_EMAIL = 'invalid_email',
  INVALID_EMAIL_IN_USER_ATTRIBUTES = 'invalid_email_in_user_attributes',
  NOT_AFTER_FAILURE = 'not_after_failure',
  NOT_BEFORE_FAILURE = 'not_before_failure',
  NO_NAME_ID_FOUND = 'no_nameID_found',
  RESPONDENT_ONLY = 'respondent_only',
  REQUEST_DENIED = 'request_denied',
  SAML_RESPONSE_ALREADY_SEEN = 'saml_response_already_seen',
  SSO_NOT_ENABLED = 'sso_not_enabled',
  USER_PENDING_DELETION = 'user_pending_deletion',
  USER_IS_PENDING_DELETION = 'user_is_pending_deletion',
  USER_PENDING_REASSIGNMENT = 'user_pending_reassignment',
  USER_IS_PENDING_REASSIGNMENT = 'user_is_pending_reassignment',
  X509_CANNOT_VERIFY = 'x509_cannot_verify',
  X509_MISMATCH = 'x509_mismatch',

  // Non Usersvc codes
  CONNECTION_DISABLED = 'connection_disabled',
  IDP_INITIATED_NOT_ENABLED = 'idp_init_not_enabled',
}

export enum MFAErrorCode {
  MFA_TOO_MANY_CODE_ATTEMPTS = 'mfa_too_many_code_attempts',
}

export type ErrorCode =
  | 'accept_forbidden'
  | 'content_type_not_acceptable'
  | 'data_center_mismatch'
  | 'identity_already_linked'
  | 'identity_not_linked'
  | 'internal_error'
  | 'invalid_invite'
  | 'invalid_request'
  | 'method_not_allowed'
  | 'not_enough_seats'
  | 'profile'
  | 'profile_not_found'
  | 'multiple_profiles_found'
  | 'rate_limit_exceeded'
  | 'resource_not_found'
  | 'temporarily_unavailable'
  | 'unauthorized'
  | 'user_already_in_group'
  | 'user_is_hipaa';

type ErrorSource =
  | {
      location: 'body';
      pointer: string;
    }
  | {
      location: 'headers';
      header: string;
    }
  | {
      location: 'path';
    }
  | {
      location: 'query';
      parameter: string;
    };

export type FormattedError = {
  code: ErrorCode | SamlErrorCode;
  detail: string;
  source?: ErrorSource;
  retryAfter?: string;
  isEmailInUse?: boolean;
  extra?: string;
};
