import { ParseResult } from '..';

export type Handler = {
  setNext: (handler: Handler) => Handler;

  handle: (error: string, errorDescription: string) => ParseResult | null;
};

export abstract class AbstractHandler implements Handler {
  private nextHandler: Handler | undefined;

  public setNext(handler: Handler): Handler {
    this.nextHandler = handler;
    // Returning a handler from here will let us link handlers in a
    // convenient way
    return handler;
  }

  public handle(error: string, errorDescription: string): ParseResult | null {
    if (this.nextHandler) {
      return this.nextHandler.handle(error, errorDescription);
    }

    return null;
  }
}
