import asEnum from '~common/helpers/asEnum';

/**
 * An Auth0 login strategy. The strategy appears in Auth0-issued ID Tokens as the leftmost
 * segment of the `sub` claim.
 *
 * Example sub claims:
 * - `auth0|deadbeef`
 * - `facebook|111222333444`
 * - `samlp|bigcorp|user@email.com`
 */
export enum Auth0Strategy {
  Apple = 'apple',
  Auth0 = 'auth0',
  Facebook = 'facebook',
  Google = 'google-oauth2',
  LinkedIn = 'linkedin',
  Microsoft = 'windowslive',
  Saml = 'samlp',
}

export function isSocial(s: Auth0Strategy): boolean {
  switch (s) {
    case Auth0Strategy.Apple:
    case Auth0Strategy.Facebook:
    case Auth0Strategy.Google:
    case Auth0Strategy.LinkedIn:
    case Auth0Strategy.Microsoft:
      return true;
    default:
      return false;
  }
}

/** Gets the Auth0 login strategy from a `sub` claim */
export function getStrategyBySub(sub: string): Auth0Strategy {
  const [strategyPart] = sub.split('|');
  return asEnum(Auth0Strategy, strategyPart);
}
