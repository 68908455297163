const ROUTES = Object.freeze({
  LANDING: '/login/team/join/:inviteToken',
  CREATE: '/login/team/join/:inviteToken/create',
  CONVERT: '/login/team/join/:inviteToken/convert',

  REASSIGN_LANDING: '/login/team/reassign/:inviteToken',
  REASSIGN_CONFIRM: '/login/team/reassign/:inviteToken/confirm',
});

export default ROUTES;
