import { IdToken } from '@auth0/auth0-spa-js';
import { clientErrorHandler } from '@sm/webassets';

type respondentSSOTokenBody = {
  userId: string;
  userAuthCookie: string;
  redirectUrl?: string;
};

export class RespondentSSOClient {
  readonly createRespondentSSOTokenendpointUrl = '/login/api/v1/respondent/actions/create_respondent_sso_token';

  async createRespondentSSOToken(idToken: IdToken, ep: string): Promise<respondentSSOTokenBody> {
    const resp = await fetch(this.createRespondentSSOTokenendpointUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id_token: idToken.__raw,
        ep,
      }),
    });

    if (resp.status === 200) {
      return (await resp.json()) as respondentSSOTokenBody;
    }

    const error = new Error('Failed to create responent SSO Token');
    clientErrorHandler.logError(error);
    throw error;
  }
}

export default (): RespondentSSOClient => new RespondentSSOClient();
