import { StaticContext } from '@sm/webassets';
import React, { useContext } from 'react';
import callback from '~app/handlers/callback';
import LoadingPage from '~app/pages/login/LoadingPage/LoadingPage';
import useAuth0Config from '~app/pages/login/hooks';

export default function HandleAuthCallbackPage(): JSX.Element {
  const {
    'client-config': { isLegacySso },
  } = useContext(StaticContext);

  const auth0Config = useAuth0Config();

  return <LoadingPage handler={() => callback(auth0Config, isLegacySso)} />;
}
