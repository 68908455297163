import SMEnviron from '@sm/environ';
import { L10nProvider } from '@sm/intl';
import { LanguageCode } from '@sm/locale';
import { ApolloClient, NormalizedCacheObject, ApolloProvider } from '@apollo/client';
import { ErrorBoundary, FiveHundredErrorPage, HelmetProvider, StaticProvider } from '@sm/webassets';
import React, { ReactElement } from 'react';
import { Router } from 'react-router-dom';
import App from '../App';
import history from './history';

export default function createApp(
  environ: SMEnviron,
  staticData: { [K in string]: unknown },
  apolloClient: ApolloClient<NormalizedCacheObject>
): (msgs: { [K: string]: string }) => ReactElement {
  return (localeMessages: { [K: string]: string }) => (
    <ErrorBoundary FallbackComponent={FiveHundredErrorPage}>
      <HelmetProvider>
        <ApolloProvider client={apolloClient}>
          <Router history={history}>
            <StaticProvider value={{ ...staticData }}>
              <L10nProvider
                localeCode={environ.slLanguageLocale}
                languageCode={environ.languageCode as LanguageCode}
                localeMessages={localeMessages}
              >
                <App />
              </L10nProvider>
            </StaticProvider>
          </Router>
        </ApolloProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}
