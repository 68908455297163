import { ReactElement } from 'react';
import { hydrate, render } from 'react-dom';

type DocumentLike = {
  getElementById: (id: string) => HTMLElement | null;
};

export default function mount(document: DocumentLike): (app: ReactElement) => void {
  const target = document.getElementById('reactApp');
  if (!target) {
    throw new Error('App node not found in document');
  }

  return (app: ReactElement) => {
    if (target.innerHTML.trim().length) {
      return hydrate(app, target);
    }
    return render(app, target);
  };
}
