import { QueryParams } from '~common/entities/smParams';

export default function getQueryParam<T extends string>(name: QueryParams): T | undefined {
  const params = new URLSearchParams(window.location.search);
  return (params.get(name) as T) || undefined;
}

export function hasQueryParam(name: string): boolean {
  return new URLSearchParams(window.location.search).has(name);
}

/**
 * @returns The page query params as a dictionary.
 */
export function getAllQueryParams(): Record<string, unknown> {
  const params = new URLSearchParams(window.location.search);
  const obj = Object.create(null);
  params.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
}
