/**
 * This takes in a connection name and returns the canonical link of the connection
 *
 * @param connection The connection name
 * @returns The canonical link for the connection name
 */
const getCanonicalLink = (connection: string): string => `/login/sso/${connection}`;

const refreshSamlConnection = async (connection: string): Promise<boolean> => {
  const endpointUrl = '/login/api/v1/saml_connections/refresh';

  try {
    const resp = await fetch(endpointUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        connection,
      }),
    });

    if (resp.status === 200) {
      return true;
    }
  } catch (e: unknown) {
    // nothing
  }

  return false;
};

export default {
  getCanonicalLink,
  refreshSamlConnection,
};
