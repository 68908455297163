import React, { PropsWithChildren, ReactNode } from 'react';
import { CaptiveWindow } from '@sm/webassets';
import { createUseStyles } from 'react-jss';
import { Box } from '@wds/box';
import { Theme } from '@wds/styles';

const useStyles = createUseStyles(({ palette }: Theme) => {
  return {
    unlinkedWindow: {
      maxWidth: '636px',
    },
    window: {
      backgroundColor: palette.background.main,
    },
  };
});

const UnlinkedWindow = ({ addOnTop, children }: PropsWithChildren<{ addOnTop: ReactNode }>): JSX.Element => {
  const { unlinkedWindow, window } = useStyles();
  return (
    <Box className={window}>
      <CaptiveWindow logoPosition="center" addOnTop={<div className={unlinkedWindow}>{addOnTop}</div>}>
        {children}
      </CaptiveWindow>
    </Box>
  );
};

export default UnlinkedWindow;
