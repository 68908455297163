import loadable from '@loadable/component';
import { FourOhFourError, GlobalThemeProvider } from '@sm/webassets';
import { WrenchTheme } from '@wds/styles';
import { Route, Switch, useLocation } from 'react-router-dom';
import React from 'react';

import StartAuthTransactionPage from '~app/pages/login/StartAuthTransactionPage';
import HandleAuthCallbackPage from '~app/pages/login/HandleAuthCallbackPage';
import LoadingPage from './pages/login/LoadingPage/LoadingPage';
import UnlinkedPage from './pages/login/UnlinkedPage';
import LoginErrorPage from './pages/login/ErrorPage';
import SHARED_ROUTES from './pages/sharedRoutes';
import TeamRoutes from './pages/team/routes';

// Team join pages go in their own separate chunk
const TeamPage = loadable(
  async () => import(/* webpackChunkName: 'loginweb-team-join' */ './pages/team/TeamBasePage'),
  {
    fallback: <LoadingPage handler={() => {}} />,
  }
);

// Survey share interstitial page goes in a separate chunk, since it's off the main path
const SurveyShareInterstitialPage = loadable(
  async () =>
    import(/* webpackChunkName: 'loginweb-survey-share' */ './pages/survey-share/SurveyShareInterstitialPage'),
  {
    fallback: <LoadingPage handler={() => {}} />,
  }
);

const App = (): JSX.Element => {
  const { search } = useLocation();

  return (
    <GlobalThemeProvider theme={WrenchTheme}>
      <Switch>
        <Route exact path={['/login', '/forgot-password', '/sign-up']}>
          <StartAuthTransactionPage />
        </Route>

        <Route exact path={SHARED_ROUTES.CALLBACK}>
          {/*
            We have a single callback path registered with the auth server. It routes to distinct
            login flows based on the query param. Determine which flow we're in.
              - /login/callback?team-join  => TeamPage
              - /login/callback            => normal login/sign-up
          */}
          <Choose>
            <When condition={search.includes('?team-join')}>
              <TeamPage />
            </When>
            <Otherwise>
              <HandleAuthCallbackPage />
            </Otherwise>
          </Choose>
        </Route>

        <Route exact path="/login/unlinked">
          <UnlinkedPage />
        </Route>

        <Route exact path="/login/error">
          <LoginErrorPage />
        </Route>

        <Route path="/login/sso/:ssoCanonicalName">
          <StartAuthTransactionPage />
        </Route>

        <Route path={[...Object.values(TeamRoutes)]}>
          <TeamPage />
        </Route>

        <Route exact path="/login/survey-share">
          <SurveyShareInterstitialPage />
        </Route>

        <Route path="*">
          <FourOhFourError />
        </Route>
      </Switch>
    </GlobalThemeProvider>
  );
};

export default App;
