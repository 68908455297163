import { Auth0Client, AuthorizationParams } from '@auth0/auth0-spa-js';

import { Auth0Config } from '~app/helpers/auth0Config';
import { stashSamlConnection, stashEp, stashSm, stashSmAllow } from '~app/helpers/sessionStorage';
import { getAmplitudeIds, waitForAmplitude } from '~app/utils/amplitudeHelpers';
import getSubdomain from '~app/utils/getSubdomain';
import { Auth0Strategy, isSocial } from '~common/entities/auth0Strategy';
import { QueryParams, SmParams } from '~common/entities/smParams';
import asEnum from '~common/helpers/asEnum';
import getQueryParam, { getAllQueryParams, hasQueryParam } from '../helpers/queryParams';
import { AppState, SmApp, SmShow } from './handlers';
import { getAuth0SupportedLocale, Locale } from '~app/helpers/locale';

function getRedirectUri(ep: string | undefined): string {
  return `https://${window.location.hostname}/login/callback${ep?.includes('sso_user') ? '?sso_user=' : ''}`;
}

const FORCE_AUTH: AuthorizationParams['prompt'] = 'login';

function getSmShow(): SmShow | undefined {
  const showParam: SmShow | undefined = getQueryParam(QueryParams.SM_SHOW);
  if (showParam !== undefined) {
    return showParam;
  }

  const { pathname } = window.location;
  if (pathname.includes('sign-up')) {
    return SmShow.SIGNUP;
  }
  if (pathname.includes('forgot-password')) {
    return SmShow.FORGOT_PASSWORD;
  }

  return undefined;
}

function getSmApp(): SmApp | undefined {
  const value = getQueryParam(QueryParams.SM_APP);

  try {
    return asEnum(SmApp, value);
  } catch (e: unknown) {
    // value not in enum
  }
  return undefined;
}

function getSmForceAccontPicker(): true | undefined {
  if (hasQueryParam(SmParams.SM_FORCE_ACCOUNT_PICKER)) {
    return true;
  }
  return undefined;
}

function getForceAccountCreation(): true | undefined {
  const { pathname } = window.location;
  if (pathname.replace(/\/$/, '') === '/sign-up') {
    const connection = getQueryParam(QueryParams.CONNECTION);
    try {
      // When the user came into the transaction with "signup" intent and a social `connection`
      // param, they'll bypass the login page and go straight to the social IdP, so their intent
      // cannot change. Let's go ahead and force account creation in this case
      const strategy = asEnum(Auth0Strategy, connection);
      return isSocial(strategy) || undefined;
    } catch (e: unknown) {
      // unknown or no connection, ignore
    }
  }

  return undefined;
}

/**
 * @returns the params that will be persisted with the login transaction
 */
function buildAppState(): AppState {
  return {
    show: getSmShow(),
    app: getSmApp(),
    forceAccountPicker: getSmForceAccontPicker(),
    ut_source: getQueryParam(QueryParams.UT_SOURCE),
    ut_source2: getQueryParam(QueryParams.UT_SOURCE2),
    ut_source3: getQueryParam(QueryParams.UT_SOURCE3),
    authUrl: window.location.toString(),
    forceAccountCreation: getForceAccountCreation(),
  };
}

// URL opener function for the auth redirect. Because the current /login route is just a transitional
// spinner screen, it shouldn't appear in the user's browsing history, hence location.replace() it
async function replaceUrl(url: string): Promise<void> {
  window.location.replace(url);
  return Promise.resolve();
}

/**
 * @returns Which Auth0 connection to initiate login against. If undefined, LoginApp UI will be
 * rendered and offer all the available options (password, social, SAML, etc).
 */
function getConnection(ssoCanonicalName?: string): string | undefined {
  return getQueryParam(QueryParams.CONNECTION) ?? ssoCanonicalName ?? undefined;
}

/**
 * Begins a login transaction, storing the per-transaction params in
 * sessionStorage. Then navigates to the authentication provider.
 * Handles both sign-ups and logins, given the user can switch intent
 * on the login page.
 *
 * @param auth0Config Auth0 configuration
 * @param ep Optional URI where the user should be sent after completing the transaction
 * @param language The user's language code, will be passed to LoginApp to control display language
 * @param country The user's country, will be passed to LoginApp to adjust country-specific look & feel
 * @param smAllowCreateUserCookieVal
 * @param ssoCanonicalName Initiate login against the Auth0 connection with this name. This is used
 * in SSO flows when we already know what IdP the user wants.
 */
export default async function login({
  auth0Config,
  ep,
  language,
  country,
  smAllowCreateUserCookieVal,
  ssoCanonicalName,
}: {
  auth0Config: Auth0Config;
  ep?: string;
  language?: string;
  country?: string;
  smAllowCreateUserCookieVal?: string;
  ssoCanonicalName?: string;
}): Promise<void> {
  const { domain, clientId, now } = auth0Config;
  if (!domain || !clientId) {
    throw new Error(`Missing auth0 configuration`);
  }

  const client = new Auth0Client({
    clientId,
    domain,
    authorizationParams: {
      redirect_uri: getRedirectUri(ep),
    },
    nowProvider: () => now,
  });

  const sm = getQueryParam(QueryParams.SM);
  const smAllowCreateUser = smAllowCreateUserCookieVal ?? getQueryParam(QueryParams.SM_ALLOW_CREATE_USER);
  const queryObj = getAllQueryParams();
  const connection = getConnection(ssoCanonicalName);

  // This saves the connection name in session storage to be used
  // when a user is done with their SSO authentication
  stashSamlConnection(connection ?? '');

  let enableSocialPopup = false;
  if (ep !== undefined) {
    stashEp(ep);

    enableSocialPopup = ep.startsWith('/oauth/authorize?');
  }
  if (sm !== undefined) {
    stashSm(sm);
  }
  if (smAllowCreateUser !== undefined) {
    stashSmAllow(smAllowCreateUser);
  }

  // Wait for the Amplitude SDK to drop a session ID. This is important, as passing the Amp session
  // to Auth0 is what makes tracking work across the OAuth flow and back.
  await waitForAmplitude();

  const appState = buildAppState();
  const lang = language ?? 'en';
  const amplitudeIds = getAmplitudeIds();

  // navigates away
  client
    .loginWithRedirect({
      appState,
      openUrl: replaceUrl,
      authorizationParams: {
        ...queryObj, // propagate all our query params over to Auth0 (incl. 'ep' if we have it)
        ui_locales: getAuth0SupportedLocale(lang as Locale),
        [SmParams.SM_AMP_DID]: amplitudeIds.deviceId ?? undefined,
        [SmParams.SM_AMP_SID]: amplitudeIds.sessionId ?? undefined,
        [SmParams.SM_APP]: appState.app,
        [SmParams.SM_SHOW]: appState.show,
        [SmParams.SM_LOCALE]: lang,
        [SmParams.SM_COUNTRY]: country,
        [SmParams.SM_SUBDOMAIN]: getSubdomain(),
        [SmParams.SM_SOCIAL_POPUP]: enableSocialPopup.toString(),
        connection,
        prompt: FORCE_AUTH, // SM auth cookie will redirect logged-in users; always prompt for Auth0 login
      },
    })
    ?.catch(e => {
      // eslint-disable-next-line no-console
      console.error('Failed to login with redirect', e);
    });
}
