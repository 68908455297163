import { StaticContext } from '@sm/webassets';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import login from '~app/handlers/login';
import LoadingPage from '~app/pages/login/LoadingPage/LoadingPage';
import useAuth0Config from '~app/pages/login/hooks';

/** Renders a spinner page that starts the auth transaction and redirects the user to Auth0 */
export default function StartAuthTransactionPage(): JSX.Element {
  const {
    'client-config': { ep, smAllowCreateUser },
    environment: { languageCode: language, countryCode: country },
  } = useContext(StaticContext);

  const auth0Config = useAuth0Config();

  const { ssoCanonicalName } = useParams<{ ssoCanonicalName?: string }>();

  return (
    <LoadingPage
      handler={() => {
        // Don't need to await this promise, just ignore it
        void login({
          auth0Config,
          ep,
          language,
          country,
          smAllowCreateUserCookieVal: smAllowCreateUser,
          ssoCanonicalName,
        });
      }}
    />
  );
}
