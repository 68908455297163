import { StaticContext } from '@sm/webassets';
import { useContext } from 'react';
import { Auth0Config } from '~app/helpers/auth0Config';

export default function useAuth0Config(): Auth0Config {
  const {
    'client-config': { auth0, now },
  } = useContext<{ 'client-config': { auth0: Auth0Config; now: number } }>(StaticContext);

  auth0.now = now;

  return auth0;
}
