const isEUSubdomain = (hostname: string): boolean => {
  const parts = hostname.split('.');

  return parts.length === 4 && parts[1] === 'eu';
};

export default function getSubdomain(): string | undefined {
  let subdomain = '';

  try {
    const host = window.location.hostname;

    const parts = host.split('.');
    const isUKDomain = host.endsWith('.co.uk');

    // We only want to handle domains that are in the format de.surveymonkey.com or de.eu.surveymonkey.com
    const validHost = parts.length === 3 && !isUKDomain;
    const validEUHost = isEUSubdomain(host) && !isUKDomain;

    if (validHost) {
      [subdomain] = parts;
    } else if (validEUHost) {
      subdomain = `${parts[0]}.${parts[1]}`;
    }
  } catch (e: unknown) {
    // do nothing
  }

  return subdomain;
}
