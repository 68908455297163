import { SamlErrorCode } from '~common/errors/userFacing';

import { ParseResult } from '..';
import { AbstractHandler } from '.';

export default class IDPInitiatedHandler extends AbstractHandler {
  public handle(error: string, errorDescription: string): ParseResult | null {
    const isError = error === 'invalid_request' && errorDescription.includes('IdP-Initiated login is not enabled');

    if (isError) {
      return {
        code: SamlErrorCode.IDP_INITIATED_NOT_ENABLED,
      };
    }
    return super.handle(error, errorDescription);
  }
}
