import { ProgressCircle } from '@wds/progress-circle';
import React, { useEffect, useState } from 'react';

import BaseLoadingPage from '~app/pages/login/BaseLoadingPage';
import { SamlError } from '~app/helpers/sessionsApiClient';

import navigateTo from '~app/helpers/navigateTo';
import './loading.scss';

import getQueryParam from '~app/helpers/queryParams';
import parse, { ParseResult } from '~app/utils/auth0SamlErrorParser';
import { QueryParams } from '~common/entities/smParams';

export const TEST_ID = 'LoadingPage';

type Handler = () => void | Promise<void>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPromise(v?: any): v is Promise<unknown> {
  return !!v && 'then' in v;
}

export default function LoadingPage({ handler }: { handler: Handler }): JSX.Element {
  const [, setError] = useState();
  const handleError = (e: Error): void => {
    // Auth0 throws this error if the state been returned is not valid
    const validState = e.message !== 'Invalid state';

    let auth0Error: ParseResult | null = null;
    const errorReason = getQueryParam<string>(QueryParams.ERROR);
    const errorDesc = getQueryParam<string>(QueryParams.ERROR_DESCRIPTION);

    if (errorReason && errorDesc && validState) {
      auth0Error = parse(errorReason, errorDesc);
    }

    if ((auth0Error || e instanceof SamlError) && validState) {
      const code = auth0Error?.code ?? (e as SamlError).code;

      return navigateTo('/login/error', { error: code });
    }

    // Re-throw the error inside a new hook. This ensures that the page-level
    // error boundary will trigger.
    setError(() => {
      throw e;
    });
  };

  // We're not providing deps to useEffect because we only load this
  // component on page load and we're running a script for the entire lifecycle
  // of the page.
  useEffect(() => {
    const result = handler();
    if (isPromise(result)) {
      result.catch(handleError);
    }
  });
  return (
    <BaseLoadingPage pageId="LoadingPage">
      <main className="sm-loading--centered" data-testid={TEST_ID}>
        <ProgressCircle continuous />
      </main>
    </BaseLoadingPage>
  );
}
