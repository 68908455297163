import { SamlErrorCode } from '~common/errors/userFacing';
import ConnectionDisabledHandler from './handlers/connectionDisabled';
import IDPInitiatedHandler from './handlers/idpInitiated';
import InvalidCertHandler from './handlers/invalidCert';
import InvalidThumprintHandler from './handlers/invalidThumbprint';

export type ParseResult = {
  code: SamlErrorCode;
};

export default function parse(error: string, errorDescription: string): ParseResult | null {
  const idpInitiated = new IDPInitiatedHandler();
  const invalidCert = new InvalidCertHandler();
  const invalidThumbprint = new InvalidThumprintHandler();
  const connectionDisabled = new ConnectionDisabledHandler();

  invalidThumbprint.setNext(connectionDisabled).setNext(invalidCert).setNext(idpInitiated);

  return invalidThumbprint.handle(error, decodeURIComponent(errorDescription));
}
