import { defineMessages, T } from '@sm/intl';
import React from 'react';
import { PageHeader } from '@wds/page-header';
import { createUseStyles } from 'react-jss';
import { Theme } from '@wds/styles';

import { SocialName } from './types';

export const TEST_ID = 'UnlinkedHeader';

const COPY = defineMessages({
  unlinkedPageHeader: {
    id: 'login.UnlinkedHeader.Header',
    defaultMessage: 'Your {socialName} account isn’t linked to a SurveyMonkey account',
    description:
      '[Type: Header][Vis: med] - Header of the page alerting the user their third party account is unlinked',
  },
  unlinkedEmailInUseHeader: {
    id: 'login.UnlinkedHeader.EmailInUseHeader',
    defaultMessage: 'Please log in with your password',
    description:
      '[Type: Header][Vis: med] - Header of the page informing the user there is an existing account linked to their social email, so they need to log in using their password',
  },
});

const useStyles = createUseStyles((theme: Theme) => {
  return {
    headerContainer: {
      '& > *': {
        paddingTop: 0,
        paddingBottom: theme.spacing[6],
      },
      '& *': {
        backgroundColor: 'transparent',
      },
    },
    header: {
      fontSize: theme.type.fontSize.pageTitle,
    },
  };
});

export default function UnlinkedHeader({
  socialName,
  emailInUse,
}: {
  socialName: SocialName;
  emailInUse: boolean;
}): JSX.Element {
  const { headerContainer, header } = useStyles();

  const headerText = emailInUse ? (
    <T desc={COPY.unlinkedEmailInUseHeader} />
  ) : (
    <T desc={COPY.unlinkedPageHeader} values={{ socialName }} />
  );

  return (
    <div className={headerContainer} data-testid={TEST_ID}>
      <PageHeader centered title={<div className={header}>{headerText}</div>} />
    </div>
  );
}
