import { IdToken } from '@auth0/auth0-spa-js';

const ID_TOKEN_NAMESPACE = 'unlinkedState';
const EP_PARAM_NAMESPACE = 'ep';
const SM_PARAM_NAMESPACE = 'sm';
const SM_ALLOW_CREATE_PARAM_NAMESPACE = 'sm_allow_create_user';
const CONNECTION_NAME_NAMESPACE = 'auth.conn';

function stash(namespace: string, value: string): void {
  window.sessionStorage.setItem(namespace, value);
}

function fetch(namespace: string): string | null {
  return window.sessionStorage.getItem(namespace);
}

function remove(namespace: string): void {
  window.sessionStorage.removeItem(namespace);
}

export function stashSamlConnection(connection: string): void {
  stash(CONNECTION_NAME_NAMESPACE, connection);
}

export function fetchSamlConnection(): string | null {
  return fetch(CONNECTION_NAME_NAMESPACE);
}

export function removeSamlConnection(): void {
  remove(CONNECTION_NAME_NAMESPACE);
}

export function stashIdToken(idToken: IdToken): void {
  stash(ID_TOKEN_NAMESPACE, JSON.stringify(idToken));
}

export function fetchIdToken(): IdToken | null {
  const token = fetch(ID_TOKEN_NAMESPACE);
  if (!token) {
    return null;
  }
  return JSON.parse(token) as IdToken;
}

export function removeIdToken(): void {
  remove(ID_TOKEN_NAMESPACE);
}

export function stashEp(ep: string): void {
  stash(EP_PARAM_NAMESPACE, ep);
}

export function fetchAndRemoveEp(): string | null {
  const ep = fetch(EP_PARAM_NAMESPACE);
  remove(EP_PARAM_NAMESPACE);
  return ep;
}

export function stashSm(sm: string): void {
  stash(SM_PARAM_NAMESPACE, sm);
}

export function fetchAndRemoveSm(): string | null {
  const sm = fetch(SM_PARAM_NAMESPACE);
  remove(SM_PARAM_NAMESPACE);
  return sm;
}

export function stashSmAllow(sm_allow_create_user: string): void {
  stash(SM_ALLOW_CREATE_PARAM_NAMESPACE, sm_allow_create_user);
}

export function fetchAndRemoveSmAllow(): string | null {
  const sm_allow_create_user = fetch(SM_ALLOW_CREATE_PARAM_NAMESPACE);
  remove(SM_ALLOW_CREATE_PARAM_NAMESPACE);
  return sm_allow_create_user;
}
