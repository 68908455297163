import React, { useContext, useEffect } from 'react';
import { ProgressCircle } from '@wds/progress-circle';
import { FiveHundredErrorPage, StaticContext } from '@sm/webassets';
import { t, T } from '@sm/intl';

import getQueryParam from '~app/helpers/queryParams';
import BaseLoadingPage from '~app/pages/login/BaseLoadingPage';
import { QueryParams } from '~common/entities/smParams';
import { SamlErrorCode, MFAErrorCode } from '~common/errors/userFacing';
import { getErrorMessage } from './copy';
import { fetchSamlConnection, removeSamlConnection } from '~app/helpers/sessionStorage';
import samlConnectionApi from '~app/helpers/samlConnectionApi';

export const LOADING_TEST_ID = 'LoadingPage';

export function getErrorCode(
  errorReason: string | undefined,
  errorDesc: string | undefined
): SamlErrorCode | MFAErrorCode | undefined {
  if (errorReason && Object.values<string>(SamlErrorCode).includes(errorReason)) {
    return errorReason as SamlErrorCode;
  }
  if (errorDesc && (errorDesc.includes('mfa-otp-challenge') || errorDesc.includes('mfa-otp-enrollment'))) {
    return MFAErrorCode.MFA_TOO_MANY_CODE_ATTEMPTS;
  }

  return undefined;
}

export default function ErrorPage(): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(true);

  const {
    environment: { languageCode },
    pageRequestId,
  } = useContext(StaticContext);
  const errorReason = getQueryParam<string>(QueryParams.ERROR);
  const errorDesc = getQueryParam<string>(QueryParams.ERROR_DESCRIPTION);
  const errorCode = getErrorCode(errorReason, errorDesc);
  const connection = fetchSamlConnection();

  useEffect(() => {
    (async () => {
      const shouldRefreshSamlConnection =
        !!connection &&
        errorCode &&
        (errorCode === SamlErrorCode.X509_MISMATCH || errorCode === SamlErrorCode.X509_CANNOT_VERIFY);

      try {
        if (shouldRefreshSamlConnection) {
          const refreshed = await samlConnectionApi.refreshSamlConnection(connection as string);

          // redirect user back to canonical link if connection was refreshed
          if (refreshed) {
            // Remove the connection from storage before redirecting
            removeSamlConnection();
            window.location.href = samlConnectionApi.getCanonicalLink(connection as string);
            return;
          }
        }
      } catch (e: unknown) {
        // ignore
      }

      // We want to stop showing the spinner if the page is not redirecting
      setIsLoading(false);
    })();
  });

  if (isLoading) {
    return (
      <BaseLoadingPage pageId="LoadingPage">
        <main className="sm-loading--centered" data-testid={LOADING_TEST_ID}>
          <ProgressCircle continuous />
        </main>
      </BaseLoadingPage>
    );
  }

  if (errorCode) {
    const { errorCode: errorCodeCopy, errorTitle, errorDescription, values } = getErrorMessage(languageCode, errorCode);

    return (
      <FiveHundredErrorPage
        requestId={`${t(errorCodeCopy)}: ${pageRequestId}`}
        openingMessage={t(errorTitle)}
        errorTitle="" // this is to override the default title
        errorMessage={<T desc={errorDescription} html values={values} />}
      />
    );
  }

  return <FiveHundredErrorPage requestId={pageRequestId} />;
}
