// Interfaces for the client side handler code.

import { UtSourceParams } from '~common/entities/user';

/**
 * Any additional data stored by the client that is associated with
 * a particular login transaction.
 *
 * - `ep`: "end page"; where the user is redirected to after auth
 * - `show`: what auth experience the user should first get on LoginApp
 * - `forceAccountCreation`: true to create a SurveyMonkey account instead of showing the
 *   "unlinked 3rd party account" page when possible.
 */
export type AppState = {
  ep?: string;
  show?: SmShow;
  app?: SmApp;
  forceAccountPicker?: boolean;
  forceAccountCreation?: boolean;
  sm?: string;
  sm_allow_create_user?: string;
  authUrl?: string; // The complete original login URL that the client came into the transaction with.
} & UtSourceParams;

/** Possible values for the `sm.show` query parameter */
export enum SmShow {
  SIGNUP = 'sign-up',
  LOGIN = 'log-in',
  FORGOT_PASSWORD = 'forgot-password',
}

/** Possible values for the `sm.app` query parameter */
export enum SmApp {
  CORE = 'core',
  CONTRIBUTE = 'contribute',
  REWARDS = 'rewards',
  MOBILE = 'mobile',
}
