import React, { useContext } from 'react';
import { StaticContext, BasePage, Helmet } from '@sm/webassets';
import UnlinkedPageContent from './UnlinkedPageContent';

export default function UnlinkedPage(): JSX.Element {
  const {
    locale: { isEUDC },
  } = useContext(StaticContext);
  return (
    <BasePage
      color="light"
      includeHeader={false}
      showSignupButton={!isEUDC}
      includeFooter={false}
      pageId="Third Party Unlinked"
      legacyWeb="None"
      excludeMenuItems={['anon_products_menu', 'anon_solutions_menu', 'anon_resources_menu', 'anon_plans_pricing_menu']}
    >
      <Helmet>
        <title>Create or Link Your Account</title>
      </Helmet>
      <UnlinkedPageContent />
    </BasePage>
  );
}
