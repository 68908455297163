import { Desc, defineMessages } from '@sm/intl';
import { getHelpCenterLink } from '@sm/webassets';
import { SamlErrorCode, MFAErrorCode } from '~common/errors/userFacing';

type ErrorMessage = {
  errorCode: Desc;
  errorTitle: Desc;
  errorDescription: Desc;
  values: Record<string, string>;
};

const errorCopyDescription = '[Type: Paragraph][Vis: high] - The description of a login failure.';

const COPY = defineMessages({
  ERROR_CODE: {
    id: 'login.copy.ErrorCode',
    defaultMessage: 'Error code',
    description: '[Type: Label][Vis: high] - The label of the error code to be shown',
  },
  ERROR_TITLE: {
    id: 'login.copy.Title',
    defaultMessage: 'Sorry, not able to log in',
    description: '[Type: Paragraph][Vis: high] - The reason a login failed',
  },
  DEFAULT: {
    id: 'login.copy.Default',
    defaultMessage: 'Ask your admin or contact your Customer Success Manager for help.',
    description: errorCopyDescription,
  },
  GROUP_DOWNGRADED: {
    id: 'login.copy.GroupDowngraded',
    defaultMessage: 'Your group no longer has an Enterprise plan. <a href="{contactUs}">Contact us</a> for more info.',
    description: errorCopyDescription,
  },
  GROUP_NOT_FOUND: {
    id: 'login.copy.GroupNotFound',
    defaultMessage: 'We’re not able to identify your group information. Ask your admin or contact us for help.',
    description: errorCopyDescription,
  },
  NOT_AFTER_FAILURE: {
    id: 'login.copy.NotAfterFailure',
    defaultMessage: 'We’re unable to authenticate your info at this time. Ask your admin or contact us for help.',
    description: errorCopyDescription,
  },
  NO_NAME_ID_FOUND: {
    id: 'login.copy.NoNameIDFound',
    defaultMessage: 'We’re not able to find your account ID in our system. Ask your admin or contact us for help.',
    description: errorCopyDescription,
  },
  RESPONDENT: {
    id: 'login.copy.Respondent',
    defaultMessage: 'Your account doesn’t have permissions enabled. Contact your admin or IT team for help.',
    description: errorCopyDescription,
  },
  SSO_NOT_ENABLED: {
    id: 'login.copy.SSONotEnabled',
    defaultMessage: 'Single sign-on isn’t enabled for your group. Contact your admin for more info.',
    description: errorCopyDescription,
  },
  USER_PENDING_DELETION: {
    id: 'login.copy.UserPendingDeletion',
    defaultMessage:
      'The account you’re trying to log in to may be pending deletion. <a href="{contactUs}">Contact us</a> for more info.',
    description: errorCopyDescription,
  },
  USER_PENDING_REASSIGNMENT: {
    id: 'login.copy.UserPendingReassignment',
    defaultMessage:
      'The account you’re trying to log in to was reassigned by your admin. The invitation email needs to be accepted before logging in. Contact your admin for more info.',
    description: errorCopyDescription,
  },
  X509_CANNOT_VERIFY: {
    id: 'login.copy.X509CannotVerify',
    defaultMessage:
      'We’re not able to identify your group information due to an incorrect certificate. Ask your admin or contact us for help.',
    description: errorCopyDescription,
  },
  MFA_OTP_LIMIT_ERROR_TITLE: {
    id: 'login.copy.MFAOTPLimitErrorTitle',
    defaultMessage: 'Incorrect code entered too many times',
    description: errorCopyDescription,
  },
  MFA_OTP_LIMIT_ERROR_BODY: {
    id: 'login.copy.MFAOTPLimitErrorBody',
    defaultMessage: 'Please wait a few minutes then try again.',
    description: errorCopyDescription,
  },
});

export const getErrorMessage = (languageCode: string, reason?: SamlErrorCode | MFAErrorCode): ErrorMessage => {
  const contactUsLink = getHelpCenterLink(languageCode, { path: 'contact' });
  let values: Record<string, string> = {};
  let errorDescription: Desc;
  let errorTitle: Desc = COPY.ERROR_TITLE;
  const errorCode: Desc = COPY.ERROR_CODE;

  switch (reason) {
    case SamlErrorCode.GROUP_DOWNGRADED:
    case SamlErrorCode.GROUP_HAS_NO_OWNER:
    case SamlErrorCode.GROUP_OWNER_IS_NOT_ENTERPRISE:
      values = { contactUs: contactUsLink };
      errorDescription = COPY.GROUP_DOWNGRADED;
      break;
    case SamlErrorCode.GROUP_NOT_FOUND_BY_CANONICAL_NAME:
    case SamlErrorCode.GROUP_NOT_FOUND_BY_ISSUER:
    case SamlErrorCode.GROUP_NOT_FOUND:
      errorDescription = COPY.GROUP_NOT_FOUND;
      break;
    case SamlErrorCode.NOT_AFTER_FAILURE:
    case SamlErrorCode.NOT_BEFORE_FAILURE:
    case SamlErrorCode.SAML_RESPONSE_ALREADY_SEEN:
      errorDescription = COPY.NOT_AFTER_FAILURE;
      break;
    case SamlErrorCode.NO_NAME_ID_FOUND:
      errorDescription = COPY.NO_NAME_ID_FOUND;
      break;
    case SamlErrorCode.RESPONDENT_ONLY:
      errorDescription = COPY.RESPONDENT;
      break;
    case SamlErrorCode.SSO_NOT_ENABLED:
    case SamlErrorCode.GROUP_NOT_SSO_ENABLED:
      errorDescription = COPY.SSO_NOT_ENABLED;
      break;
    case SamlErrorCode.USER_PENDING_DELETION:
    case SamlErrorCode.USER_IS_PENDING_DELETION:
      values = { contactUs: contactUsLink };
      errorDescription = COPY.USER_PENDING_DELETION;
      break;
    case SamlErrorCode.USER_PENDING_REASSIGNMENT:
    case SamlErrorCode.USER_IS_PENDING_REASSIGNMENT:
      errorDescription = COPY.USER_PENDING_REASSIGNMENT;
      break;
    case SamlErrorCode.X509_CANNOT_VERIFY:
    case SamlErrorCode.X509_MISMATCH:
      errorDescription = COPY.X509_CANNOT_VERIFY;
      break;
    case MFAErrorCode.MFA_TOO_MANY_CODE_ATTEMPTS:
      errorDescription = COPY.MFA_OTP_LIMIT_ERROR_BODY;
      errorTitle = COPY.MFA_OTP_LIMIT_ERROR_TITLE;
      break;
    default:
      errorDescription = COPY.DEFAULT;
      break;
  }

  return {
    errorCode,
    errorTitle,
    errorDescription,
    values,
  };
};

export default COPY;
