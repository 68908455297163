import { Box } from '@wds/box';
import { Responsive } from '@wds/responsive';
import { Theme } from '@wds/styles';
import { Typography } from '@wds/typography';
import React, { Fragment, ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: Theme) => {
  const mediaQueryMinScreenSm = `@media (min-width: ${theme.breakpoints.sm}px)`;
  return {
    ctaContainer: {
      marginBottom: theme.spacing[6],
      [mediaQueryMinScreenSm]: {
        width: '240px',
        marginBottom: 0,
      },
    },
    spacer: {
      [mediaQueryMinScreenSm]: {
        borderRight: `1px solid ${theme.palette.border.main}`,
      },
    },
  };
});

type CtaDefinition = {
  /** The call-to-action button */
  cta: ReactNode;

  /** Explanatory text */
  caption: ReactNode;

  /** unique id */
  id: string;
};

/**
 * Helper for rendering alternative CTAs side-by-side:
 *
 * ```
 *                How do you want to proceed?
 *                           |
 *   CALL TO ACTION #1       |  CALL TO ACTION #2
 *                           |
 * Some caption explaining   | Another caption explaining
 * the first option          | the second option
 * ```
 *
 * - On small screens it collapses into a single column with alternatives stacked vertically
 *
 * @param ctas List of calls-to-action
 * @param prompt Prompt text to show above CTAs
 * @param swapOrder When `true`, the caption is rendered above the CTA. Default is `false`
 */
export default function CTASplitter({
  ctas,
  prompt,
  swapOrder = false,
}: {
  ctas: CtaDefinition[];
  prompt?: ReactNode;
  swapOrder?: boolean;
}): ReactElement {
  const { ctaContainer, spacer } = useStyles();

  const promptText = prompt ? (
    <Box mb={6}>
      <Typography align="center" variant="body">
        {prompt}
      </Typography>
    </Box>
  ) : null;

  const justify = ctas.length > 1 ? 'space-between' : 'center';

  return (
    <>
      {promptText}
      <Responsive minWidth="sm">
        {(matches: boolean) => (
          <Box mt={4} mx={4} mb={4} flexJustify={justify} {...(matches ? { display: 'flex' } : {})}>
            {ctas.map(({ cta, caption, id }, index) => (
              <Fragment key={id}>
                <Box className={ctaContainer} display="flex" flexDirection="column" flexJustify="flex-start">
                  <Box mb={matches ? 5 : 2}>{swapOrder ? caption : cta}</Box>
                  {swapOrder ? cta : caption}
                </Box>
                {index < ctas.length - 1 ? <div className={spacer} /> : null}
              </Fragment>
            ))}
          </Box>
        )}
      </Responsive>
    </>
  );
}
