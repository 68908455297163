import { IdToken } from '@auth0/auth0-spa-js';
import { fetchIdToken } from '../../../helpers/sessionStorage';
import { SocialName } from './types';

/**
 * Given an id token from a social login, returns the display name of the social IDP.
 *
 * @param idToken
 * @returns The social IDP name. The returned values are brand/company names, so they don't
 * need to be translated.
 */
export function getSocialName(idToken: IdToken): SocialName {
  // loose type-check that idToken _is_ an IdToken
  if (typeof idToken.sub === 'undefined') {
    throw new Error('idToken not in expected IdToken shape');
  }

  const { sub } = idToken;

  // verify sub one of expected social providers
  let socialName: SocialName;
  switch (sub.split('|')[0]) {
    case 'google-oauth2':
      socialName = 'Google';
      break;
    case 'facebook':
      socialName = 'Facebook';
      break;
    case 'windowslive':
      socialName = 'Microsoft';
      break;
    case 'linkedin':
      socialName = 'LinkedIn';
      break;
    case 'apple':
      socialName = 'Apple';
      break;
    default:
      throw new Error('Unidentifiable third party social provider');
  }
  return socialName;
}

export default function getAndValidateIdToken(): { idToken: IdToken; socialName: SocialName } {
  // verify token JSON-serializable and of expected shape
  let idToken: IdToken | null;
  try {
    idToken = fetchIdToken();
  } catch (error: unknown) {
    throw new Error('idToken not in expected JSON-serializable format');
  }
  if (idToken === null) {
    throw new Error('No id token present in app state');
  }

  const socialName = getSocialName(idToken);

  return { idToken, socialName };
}
