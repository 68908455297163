import { initializeClientErrorHandler } from '@sm/webassets';

export default function bootstrapSMWeb(window: Window): SMConfig {
  const staticData = window.SM?.__LOAD_PAYLOAD_CACHE__ as SMConfig['staticData'];
  if (!staticData) {
    throw new Error('__LOAD_PAYLOAD_CACHE__ not found in window.SM');
  }

  initializeClientErrorHandler();

  const { environment: environ } = staticData;
  const locale = environ?.slLanguageLocale;

  if (!locale) {
    throw new Error('Misconfigured SMEnviron');
  }

  return {
    locale,
    environ,
    staticData,
  };
}
